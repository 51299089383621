
$flex: ("-1": 1, "-2": 2, "-3": 3);

@each $key, $value in $flex {
    .f#{$key} {
        flex: $value;
    }
}

.c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

$flexAlignItems: ("c": center, "s": flex-start, "e": flex-end, "st": stretch, "b": baseline);

@each $key, $value in $flexAlignItems {
    .a-#{$key} {
        align-items: $value;
    }

    .as-#{$key} {
      align-self: $value;
  }
}
  
$flexJustifyContent: ("c": center, "s": flex-start, "e": flex-end, "b": space-between, "a": space-around);

@each $key, $value in $flexJustifyContent {
    .j-#{$key} {
        justify-content: $value;
    }
}
  
$gap: ("2": 2px, "4": 4px, "6": 6px, "8": 8px, "10": 10px, "12": 12px, "14": 14px, "16": 16px, "18": 18px, "20": 20px, "24": 24px, "28": 28px, "32": 32px, "36": 36px, "40": 40px);

@each $key, $value in $gap {
    .g-#{$key} {
        gap: $value;
    }
}
  
$color: ("w": #ffffff, "b": #000000, "s1": #BFC6C2, "s2": #AAB2AD, "s3": #979E9A, "s4": #848A86, "s5": #717673, "s6": #5E6360, "s7": #4B4F4D, "s8": #383B39, "s9": #252726, "s10": #1A1C1B, "s11": #5C5F5C, "s12": #8F918E, "sb": #000000, "tw": #ffffff, "t0": #FDFDFD, "t1": #F8F9F8, "t2": #F1F3F2, "t3": #EBEDEC, "t4": #E5E8E6, "t5": #DEE2E0, "t6": #D7DDD9, "t7": #D0D7D3, "t8": #CAD1CD, "t9": #C3CBC6, "t10": #BDC6C1, "gr1": #E8FFF1, "gr2": #A8FFCB, "gr3": #5CF89A, "gr4": #3ADE7C, "gr5": #27BD63, "gr6": #00A542, "gr7": #008836, "gr8": #006C2B, "gr9": #005221, "gr10": #003816, "gr11": #00210D, "gr12": #414953, "r1": #FF4F17, "r2": #E1582F, "gv1": #F5FDF8, "gv2": #E4F3EA, "gv3": #CBE7D6, "gv4": #AFCBBA, "yl1": #C9E712, "yl2": #7E9300, "yv1": #FBFFE4, "yv2": #F6FFBE, "bl1": #0088E4, "bl2": #0076B8, "bl3": #252A2F, "bl4": #35493D, "bv1": #EFF9FF, "bv2": #CFEEFF, "rd1": #F26312, "rd2": #C14600, "rv1": #FFF3ED, "rv2": #FFDECC, "mt1": #3EBA86, "mt2": #007E49, "mv1": #F3FFFA, "mv2": #D8FFEF, "gm1": #59BB1E, "gm2": #328600, "gmv1": #F2FFEA, "gmv2": #DAFFC3, "ct3": #2885FF, "err1": #FD5A26, "err2": #B62C00, "sf": #0092E4, "tp": transparent, "g1": #27BD63, "b1": #318AFF);

@each $key, $value in $color {
    .c-#{$key} {
        color: $value;
    }

    .bc-#{$key} {
        background-color: $value;
    }
}
  
$padding: ("0": 0px, "2": 2px, "3": 3px, "4": 4px, "6": 6px, "8": 8px, "10": 10px, "12": 12px, "14": 14px, "16": 16px, "18": 18px, "20": 20px, "22": 22px, "24": 24px, "28": 28px, "32": 32px, "36": 36px, "40": 40px, "48": 48px);

$dir: ("": ("-top", "-bottom", "-right", "-left"), "-t": ("-top"), "-b": ("-bottom"), "-r": ("-right"), "-l": ("-left"), "-tb": ("-top", "-bottom"), "-tr": ("-top", "-right"), "-tl": ("-top", "-left"), "-br": ("-bottom", "-right"), "-bl": ("-bottom", "-left"), "-rl": ("-right", "-left"), "-tbr": ("-top", "-bottom", "-right"), "-tbl": ("-top", "-bottom", "-left"), "-trl": ("-top", "-right", "-left"), "-brl": ("-bottom", "-right", "-left"));

$width: ("-1": 1, "-2": 2, "-3": 3, "-4": 4, "-5": 5, "-6": 6);

@each $key, $value in $width {
    .sdw#{$key} {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    }

    .dsdw#{$key} {
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
    }

    .l#{$key} {
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: #{$value};
        -webkit-box-orient: vertical;
    }
}

@each $d, $suffixes in $dir {
    .bd#{$d} {
        @each $suffix in $suffixes {
            border#{$suffix}: 1px solid #e4e4e4;
        }
    }
    @each $key2, $value2 in $color {
      .bd#{$d}-#{$key2} {
        @each $suffix in $suffixes {
          border#{$suffix}: 1px solid #{$value2};
        }
      }
    }
    @each $key, $value in $width {
        .bdw#{$key}#{$d} {
            @each $suffix in $suffixes {
                border#{$suffix}: #{$value}px solid #e4e4e4;
            }
        }
        @each $key2, $value2 in $color {
          .bdw#{$key}#{$d}-#{$key2} {
            @each $suffix in $suffixes {
              border#{$suffix}: #{$value}px solid #{$value2};
            }
          }
        }
    }
    @each $key, $value in $padding {
        .m-#{$key}#{$d} {
        @each $suffix in $suffixes {
            margin#{$suffix}: #{$value};
        }
        }
        .p-#{$key}#{$d} {
        @each $suffix in $suffixes {
            padding#{$suffix}: #{$value};
        }
        }
    }
}

$font-size: ("10": (10px, 14px), "12": (12px, 16px), "13": (13px, 17px), "14": (14px, 20px), "16": (16px, 24px), "18": (18px, 26px), "20": (20px, 24px), "22": (22px, 26px), "24": (24px, 28px), "28": (28px, 30px), "32": (32px, 40px), "36": (36px, 44px), "40": (40px, 46px), "48": (48px, 52px), "56": (56px, 66px), "64": (64px, 70px), "68": (68px, 76px));

$font-weight: ("900": (900, "Font-800"), "800": (800, "Font-800"), "700": (700, "Font-600"), "600": (600, "Font-600"), "500": (500, "Font-400"), "400": (400, "Font-400"));

@each $key, $value in $font-weight {
  .t-#{$key} {
    font-weight: nth($value, 1);
    font-family: nth($value, 2);
  }
}
@each $key, $values in $font-size {
  .t-#{$key} {
    font-size: nth($values, 1);
    line-height: nth($values, 2);
    min-height: nth($values, 2);
    font-weight: 500;
  }
  @each $key2, $value2 in $color {
    .t-#{$key}-#{$key2} {
      font-size: nth($values, 1);
      line-height: nth($values, 2);
      min-height: nth($values, 2);
      color: $value2;
      font-weight: 500;
    }
  }
  @each $key2, $value2 in $font-weight {
    .t-#{$key}-#{$key2} {
      font-size: nth($values, 1);
      line-height: nth($values, 2);
      min-height: nth($values, 2);
      font-weight: nth($value2, 1);
      font-family: nth($value2, 2);
    }
    @each $key3, $value3 in $color {
      .t-#{$key}-#{$key2}-#{$key3} {
        font-size: nth($values, 1);
        line-height: nth($values, 2);
        min-height: nth($values, 2);
        font-weight: nth($value2, 1);
        font-family: nth($value2, 2);
        color: $value3;
      }
    }
  }
}
      
$dimensions: ("2": 2px, "4": 4px, "6": 6px, "8": 8px, "10": 10px, "12": 12px, "16": 16px, "20": 20px, "24": 24px, "28": 28px, "32": 32px, "40": 40px, "48": 48px, "56": 56px, "85": 85px);

@each $key, $value in $dimensions {
    .d-#{$key} {
        width: $value;
        height: $value;
    }
    .dh-#{$key} {
        height: $value;
    }
    .dw-#{$key} {
        width: $value;
    }

    @each $key2, $value2 in $dimensions {
        .d-#{$key}-#{$key2} {
            width: $value;
            height: $value2;
        }
    }

    .bdr-#{$key} {
        overflow: hidden;
        border-radius: $value;
    }
}
  
$anim: ("-1": 0.2s, "-2": 0.4s, "-3": 0.6s, "-4": 0.8s, "-5": 1.0s);

@each $key, $value in $anim {
    .anim#{$key} {
      -webkit-transition: all $value ease;
      -moz-transition: all $value ease;
      transition: all $value ease;
    }
}

