@import "src/app.scss";

.react-calendar {
  width: 305px;
  max-width: 100%;
  font-family: "Font-400";
  line-height: 1.125em;
  border-bottom: 1px solid #ebedec;
  padding-bottom: 16px;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  margin-bottom: 16px;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 58px;
  padding-left: 16px;
  padding-right: 16px;
}
.react-calendar__navigation button {
  padding: 0px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  height: 24px;
  align-items: center;
  font-family: "Font-400";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #8f918e;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days {
  padding-left: 16px;
  padding-right: 16px;
}
.react-calendar__month-view__days__day {
  @extend .c-s7;
  font-family: "Font-400";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  @extend .c-s1;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__century-view .react-calendar__tile {
  padding: 15px;
  height: 50px;
  display: inline-block;
  white-space: break-spaces;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 0px;
  background: none;
  text-align: center;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}
.react-calendar__navigation__label__labelText {
  @extend .t-16-700;
}
abbr[title] {
  text-decoration: none;
}
.calendar-tile-disable abbr {
  display: none;
}

.calendar-tile {
  z-index: 2;
}

.react-calendar__tile:enabled:hover .calendar-tile,
.react-calendar__tile:enabled:focus .calendar-tile {
  background-color: #f1f3f2;
  color: #191c1a;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-week-indicator {
  z-index: 1;
  position: absolute;
  left: 16px;
  width: 208px;
  height: 34px;
  background-color: #eff9f3;
  border-radius: 4px;
}

.calendar-hack .react-calendar__tile--now .calendar-tile {
  background-color: #27bd63 !important;
  color: white !important;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-tile-selected {
  background-color: #27bd63 !important;
  color: white !important;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar__navigation__prev-button {
  margin: 0 !important;
}

.react-calendar__navigation__next-button {
  margin: 0 !important;
}

.react-calendar__navigation__label {
  margin: 0 !important;
}
