@import "./layout_generated.scss";

table {
  width: 100%;
  border-spacing: 0px;

  thead th {
    @extend .p-8-l;
    @extend .t-14-s4;
    text-align: left;
    border-bottom: 1px solid #cad1ce;
  }

  tbody td {
    @extend .p-12-tb;
    @extend .p-10-l;
    @extend .t-14-s8;
    text-align: left;
    border-bottom: 1px solid #ebedec;
  }

  tbody tr:hover {
    cursor: pointer;
  }

  .column_resizer_body {
    padding: 0;
    margin: 0;
    border-left: none;
    border-right: none;
    border-right: 1px solid #ebedec;
  }
  .column_resizer_body.none {
    border-right: none;
  }
  .columnResizer {
    width: 3px !important;
    min-width: 3px !important;
    max-width: 3px !important;
    height: 100%;
    min-height: 0;
    padding: 0;
    position: relative;
    border-right: none;
  }
  .columnResizer:not(.disabled_column_resize):after {
    content: "";
    position: absolute;
    left: 1.5px;
    bottom: 0;
    width: 3px;
    height: 100%;
    min-height: 0;
  }
  .columnResizer:not(.disabled_column_resize):hover::after {
    content: "";
    position: absolute;
    left: 1.5px;
    bottom: 0;
    width: 3px;
    height: 100%;
    min-height: 0;
    @extend .bc-gr5;
  }
}
