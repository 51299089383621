@import "src/layouts/layout.scss";
@import "src/layouts/layout_generated.scss";

.date-time-picker {
  width: 312px;
  // max-height: 500px;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15),
    0px 4px 8px 1px rgba(0, 0, 0, 0.2);
  @extend .bc-tw;

  .react-datepicker {
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    filter: none;
  }

  .react-datepicker__input-container {
    display: none;
  }

  .react-datepicker-popper {
    position: initial !important;
    transform: none !important;
  }

  .react-datepicker__day-names {
    width: 100%;
    height: 32px;
    display: flex;
    margin: 0;

    .react-datepicker__day-name {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      @extend .t-14-500-s7;
    }
  }

  .react-datepicker__month-container {
    width: 280px;
    .react-datepicker__month {
      margin: 0;
      display: flex;
      flex-direction: column;
      // gap: 8px;

      .react-datepicker__week {
        height: fit-content;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: flex-start;

        .react-datepicker__day {
          flex: 1;
          display: flex;
          padding: 0;
          margin: 0;
          @extend .t-14-500-s8;

          .day-container {
            display: flex;
            height: 40px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
          }
          .day-container:hover {
            @extend .bc-t5;
          }
        }
        .react-datepicker__day:hover {
          @extend .bc-tw;
        }
        .react-datepicker__day.react-datepicker__day--today {
          @extend .bc-tw;
          @extend .c-g1;
        }
        .react-datepicker__day.react-datepicker__day--outside-month {
          .day-container {
            @extend .c-s2;
          }
        }
        .react-datepicker__day.react-datepicker__day--outside-month {
          .day-container:hover {
            @extend .bc-t5;
          }
        }
        .react-datepicker__day.react-datepicker__day--keyboard-selected {
          background: none;
        }
        .react-datepicker__day.react-datepicker__day--selected {
          background: none;
          .day-container {
            @extend .bc-g1;
            color: white !important;
          }
        }
      }
    }
  }

  .react-datepicker__year {
    width: 100%;
    margin: 0px;
    display: flex;

    .react-datepicker__year-wrapper {
      flex: 1;
      flex-direction: column;
      flex-wrap: nowrap;
      max-width: inherit;
      max-height: 284px;
      overflow-y: auto;
      display: flex;
      .react-datepicker__year-text {
        width: inherit;
        min-height: 46px;
        margin: 0;
        box-sizing: border-box;
        padding: 0px 8px;
        align-items: center;
        display: flex;
        cursor: pointer;
        @extend .t-14-500-s8;
        @extend .bdr-8;
      }
      .react-datepicker__year-text--selected {
        @extend .bc-t1;
      }
      .react-datepicker__year-text:hover {
        @extend .bc-t2;
      }
    }
  }

  .react-datepicker__monthPicker {
    width: 100%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    max-height: 284px;
    overflow-y: auto;

    .react-datepicker__month-wrapper {
      flex: 1;
      flex-direction: column;
      flex-wrap: nowrap;
      max-width: inherit;
      display: flex;

      .react-datepicker__month-text {
        width: inherit;
        min-height: 46px;
        margin: 0;
        box-sizing: border-box;
        padding: 0px 8px;
        align-items: center;
        display: flex;
        cursor: pointer;
        @extend .t-14-500-s8;
        @extend .bdr-8;
      }

      .react-datepicker__month-text--selected {
        background: #f8f9f8 !important;
      }
      .react-datepicker__month-text--keyboard-selected {
        background: none;
      }

      .react-datepicker__month-text:hover {
        @extend .bc-t2;
      }
    }
  }

  .react-datepicker__time-container {
    width: 100%;
    flex: 1;
    max-height: 236px;
    border: none;

    .time-option-item {
      width: 100%;
      height: 38px;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @extend .t-14-500-s8;
      @extend .sized;
    }

    .time-option-item:hover {
      @extend .bc-t2;
    }

    .time-option-item--selected {
      @extend .bc-t1;
    }
  }
}
