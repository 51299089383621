@import "./layout_generated.scss";

.search {
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;
  position: relative;
}
// status

.search.disabled {
  @extend .bc-t1;
  cursor: none;
}

.search.focused {
  @extend .bd-g1;
}

// type
.search.outline {
  @extend .bc-tw;
  @extend .bd-t5;
}

.search.fill {
  @extend .bc-t2;
}

// size

.search.S {
  padding: 8px;
  gap: 4px;
  border-radius: 12px;
}

.search.M {
  padding: 12px 16px;
  gap: 8px;
  border-radius: 16px;
}
