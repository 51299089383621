@import "src/layouts/layout.scss";

@font-face {
  font-family: "Font-400";
  src: url("fonts/Pretendard-Regular.woff2") format("woff2 supports variations"),
    url("fonts/Pretendard-Regular.woff2") format("woff2-variations");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Font-600";
  src: url("fonts/Pretendard-SemiBold.woff2")
      format("woff2 supports variations"),
    url("fonts/Pretendard-SemiBold.woff2") format("woff2-variations");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Font-800";
  src: url("fonts/Pretendard-Bold.woff2") format("woff2 supports variations"),
    url("fonts/Pretendard-Bold.woff2") format("woff2-variations");
  font-weight: 800;
  font-style: normal;
}

button,
textarea,
input {
  font-family: "Font-400";
  font-weight: 500;
  font-style: normal;
  padding: 0px;
}

input::placeholder {
  @extend .c-s1;
}

iframe {
  border: none;
  height: 100%;
}

input[type="file"] {
  display: none;
}

div {
  box-sizing: border-box;
  white-space: nowrap;
}

body {
  @extend .bc-w;
  font-family: "Font-400";
  font-weight: 400;
  font-style: normal;
  margin: 0px;
}

img {
  object-fit: cover;
  object-position: center;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

a:-webkit-any-link {
  color: inherit;
  text-decoration: inherit;
}

@media (max-width: 576px) {
  .desktop-only {
    display: none !important;
  }
}

.app {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

input {
  border: none;
  background-color: transparent;
}

input,
textarea {
  border: none;
  background-color: transparent;
  resize: none;
}

::-webkit-resizer {
  display: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
[contenteditable]:focus {
  outline: none;
}

.button {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.info-box {
  background-color: #f0f1ed;
  padding: 16px;
  border-radius: 8px;
}

.divider {
  @extend .as-st;
  @extend .bc-t3;
  flex-shrink: 0;
}

.drawer-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000080;
  z-index: 200000000000;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000080;
  z-index: 200000000000;
  display: flex;
}

.percentage_input {
  position: relative;
}

.percentage_input-label::after {
  content: "%";
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
