@import "./layout_generated.scss";

button {
  outline: none;
  border: none;
  cursor: pointer;
  @extend .c-s8;
}

// size
.XS {
  @extend .t-14-600;
  border-radius: 12px;
  padding: 8px 16px;
}

.S {
}

.M {
  @extend .t-18-600;
  border-radius: 16px;
  padding: 16px 24px;
}

.L {
}

// type
.filled {
  @extend .bc-g1;
  @extend .c-tw;
}

.color_outlined {
  background: rgba(39, 189, 99, 0.04);
  @extend .bd-g1;
  @extend .c-g1;
}

.grey_tint {
  @extend .bc-t2;
  @extend .c-s8;
}

.grey_outlined {
  @extend .bd-t5;
  @extend .bc-w;
  @extend .c-s8;
}

.warning {
}

.payment {
}

.text {
}
