@import "src/layouts/layout.scss";
@import "src/layouts/layout_generated.scss";

.swiper-pagination {
  position: absolute;
  width: 100%;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  bottom: auto;
}

.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background-color: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  @extend .bc-s8;
}
