@import "./layout_generated.scss";

.f-h {
  display: flex;
  flex-direction: row;
}

.f-v {
  display: flex;
  flex-direction: column;
}

.hover-popup {
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 3000;
}

// Miscellanous
.abs {
  position: absolute;
}

.rel {
  position: relative;
}

.sized {
  flex-shrink: 0;
}

.clickable {
  pointer-events: all;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.unclickable {
  pointer-events: none;
}

.ovf-h {
  overflow: hidden;
}

.ovf-s {
  overflow: scroll !important;
  overscroll-behavior-x: contain;
}

.ovf-a {
  overflow: auto !important;
  overscroll-behavior-x: contain;
}

.ovf-v {
  overflow: visible !important;
}

.ovf-s-d {
  overflow: scroll !important;
  overscroll-behavior-x: contain;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.ovf-s-d::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

.hover:hover {
  @extend .bc-t1;
}

.hover:hover .hover-popup {
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.pop-hover {
  border: 2px solid transparent;
  -webkit-transition: border 0.2s ease;
  -moz-transition: border 0.2s ease;
  transition: border 0.2s ease;
}

.pop-hover:hover {
  border: 2px solid #27bd63;
  box-sizing: border-box;
}

.op-hover {
  opacity: 1;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

.op-hover:hover {
  opacity: 0.8;
}

.snack-hover {
  background: transparent;
  -webkit-transition: background 0.2s ease;
  -moz-transition: background 0.2s ease;
  transition: background 0.2s ease;
}

.snack-hover:hover {
  background: rgba(255, 255, 255, 0.08);
}

.sidebar-hover {
  @extend .bc-t1;
}

.sidebar-hover:hover {
  @extend .bc-t2;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.transition {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.wrap {
  white-space: pre-wrap;
}

.l-m {
  display: inline-block;
  white-space: break-spaces;
}

@keyframes shake {
  0%,
  100% {
    transform: translate(2px, 0px);
  }
  25% {
    transform: translate(0px, 2px);
  }
  75% {
    transform: translate(2px, 2px);
  }
}

.shake {
  animation: shake 0.1s linear infinite;
}

.bottomsheet {
  z-index: 100000000000;
  position: absolute;
}

@keyframes move-border {
  50% {
    background-position: 100% 50%;
  }
}

.rotate-border {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      60deg,
      hsl(224, 85%, 66%),
      hsl(269, 85%, 66%),
      hsl(314, 85%, 66%),
      hsl(359, 85%, 66%),
      hsl(44, 85%, 66%),
      hsl(89, 85%, 66%),
      hsl(134, 85%, 66%),
      hsl(179, 85%, 66%)
    );
    background-size: 300% 300%;
    background-position: 0 50%;
    animation: move-border 4s alternate infinite;
  }
}
