@import "/src/app.scss";

.table-row:hover {
  background-color: #f6f7f7;
}

.table-row-column {
  padding-left: 16px;
  padding-right: 16px;
  border-right: 1px solid #e7e8e8;
  display: flex;
  align-self: stretch;
  align-items: center;
  color: #444845;
  width: 20%;
}

.table-cell-content {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
