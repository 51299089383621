@import "./layout_generated.scss";

.menu {
  padding: 8px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.2),
    0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}

.menu-item {
  // @extend .hover;
  @extend .t-14-500-s8;
  border-radius: 8px;
  padding: 8px;
}
