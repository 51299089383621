@import "./layout_generated.scss";

.filter-chip {
  @extend .bc-tw;
  @extend .bd-t5;
  @extend .c-c;
  @extend .c-s8;
  cursor: pointer;
}

// size
.filter-chip.S {
  max-width: 270px;
  border-radius: 12px;
  padding: 6px 12px;
  gap: 8;
  @extend .t-14-500;
}

.filter-chip.M {
  border-radius: 16px;
  padding: 8px 16px;
  gap: 8;
  @extend .t-14-500;
}

.filter-chip.L {
  @extend .t-16-500;
  border-radius: 16px;
  padding: 16px 20px;
  gap: 8;
}

// type
.filter-chip.selected {
  @extend .bd-g1;
  @extend .c-g1;
  background-color: rgba(39, 189, 99, 4%);
}
