@keyframes move-up-down {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 8px;
  }
}

.spinner-1 {
  bottom: 0;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #0092e4;
  animation: move-up-down 1s linear infinite;
  animation-delay: 0ms;
}

.spinner-2 {
  bottom: 0;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #3eba86;
  animation: move-up-down 1s linear infinite;
  animation-delay: 250ms;
}

.spinner-3 {
  bottom: 0;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #c9e612;
  animation: move-up-down 1s linear infinite;
  animation-delay: 500ms;
}

.spinner-4 {
  bottom: 0;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #f26312;
  animation: move-up-down 1s linear infinite;
  animation-delay: 750ms;
}
