@import "./layout.scss";

.notification {
  padding: 16px;
}

.notification.container {
  border-radius: 12px;
  border: 1px;
}
.notification.full {
}

.notification.err {
  background: #ff685e;
}
.notification.red {
  background: rgba(255, 79, 23, 0.1);

  .link {
    @extend .c-r1;
  }
}
.notification.red.container {
  border: solid rgba(255, 79, 23, 0.3);
}
.notification.blue {
  background: rgba(49, 138, 255, 0.1);
  .link {
    @extend .c-b1;
  }
}
.notification.blue.container {
  border: 1px solid rgba(49, 138, 255, 0.3);
}
