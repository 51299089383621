@import "src/layouts/layout.scss";
@import "src/layouts/layout_generated.scss";

.time-picker {
  width: 312px;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15),
    0px 4px 8px 1px rgba(0, 0, 0, 0.2);
  @extend .bc-tw;

  .time-container {
    width: 100%;
    flex: 1;
    max-height: 236px;
    border: none;

    .time-option-item {
      width: 100%;
      height: 38px;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @extend .t-14-500-s8;
      @extend .sized;
    }

    .time-option-item:hover {
      @extend .bc-t2;
    }

    .time-option-item--selected {
      @extend .bc-t1;
    }
  }
}
