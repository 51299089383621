@import "./layout.scss";

.primary-large-button {
  @extend .sized;
  @extend .c-c;
  @extend .t-18-700-w;
  @extend .bc-gr5;
  @extend .bdr-4;
  @extend .p-32-rl;
  height: 56px;

  &.disabled {
    @extend .bc-w;
    opacity: 0.6;
  }
}

.primary-button {
  @extend .sized;
  @extend .c-c;
  @extend .t-18-700-w;
  @extend .bc-gr5;
  @extend .bdr-8;
  @extend .p-16-rl;
  height: 40px;

  &.disabled {
    opacity: 0.6;
  }
}

.cta-lg-button {
  @extend .sized;
  @extend .c-c;
  @extend .t-18-600-w;
  @extend .bc-gr5;
  @extend .bdr-24;
  @extend .p-32-rl;
  height: 64px;

  &.disabled {
    opacity: 0.6;
  }
}

.cancel-button {
  @extend .sized;
  @extend .c-c;
  @extend .t-14-600-s7;
  @extend .bc-t2;
  @extend .bdr-12;
  @extend .p-16-rl;
  height: 38px;

  &.disabled {
    opacity: 0.6;
  }
}

.add-button {
  @extend .sized;
  @extend .c-c;
  @extend .t-14-600-w;
  @extend .bc-gr5;
  @extend .bdr-12;
  @extend .p-16-rl;
  height: 40px;

  &.disabled {
    opacity: 0.6;
  }
}

.confirm-button {
  @extend .sized;
  @extend .c-c;
  @extend .t-14-600-w;
  @extend .bc-gr5;
  @extend .bdr-12;
  @extend .p-16-rl;
  height: 38px;

  &.disabled {
    opacity: 0.6;
  }
}

.delete-button {
  @extend .sized;
  @extend .c-c;
  @extend .t-14-600-tw;
  @extend .bc-r1;
  @extend .bd-rv1;
  @extend .bdr-12;
  @extend .p-16-rl;
  height: 38px;

  &.disabled {
    opacity: 0.6;
  }
}

.warning-button {
  @extend .sized;
  @extend .c-c;
  @extend .t-14-600-w;
  @extend .bc-r1;
  @extend .bdr-12;
  @extend .p-16-rl;
  height: 38px;

  &.disabled {
    opacity: 0.6;
  }
}

.confirm-lg-button {
  @extend .sized;
  @extend .c-c;
  @extend .t-14-500-w;
  @extend .bc-gr5;
  @extend .bdr-8;
  @extend .p-24-rl;
  height: 54px;
  width: 224px;

  &.disabled {
    opacity: 0.6;
  }
}

.warning-lg-button {
  @extend .sized;
  @extend .c-c;
  @extend .t-14-500-err2;
  @extend .bc-rv1;
  @extend .bdr-8;
  @extend .p-24-rl;
  height: 54px;
  width: 224px;

  &.disabled {
    opacity: 0.6;
  }
}
