@import "src/layouts/layout.scss";

/* .item must be first */

.pagination-page {
  width: 100%;
  height: 100%;
}

.active .item {
  @extend .t-14-600-s8;
  @extend .bc-t2;
  border-radius: 8px;
}

a.item:hover {
  @extend .t-14-600-s8;
  @extend .bc-t2;
  border-radius: 8px;
}

.item {
  @extend .c-c;
  @extend .t-14-500-s6;
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.item a {
  @extend .f-h;
  @extend .c-c;
}

.disabled-page {
  color: #5e6361;
}

.pagination {
  padding: 0;
  margin: 0;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 30px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  gap: 4px;
}

.previous {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.next {
  width: 24px;
  height: 24px;
  margin-left: 12px;
}
