.year-month-picker {
  .react-datepicker {
    width: 258px !important;
    padding: 24px 16px 16px !important;
  }

  .react-datepicker__month-wrapper {
    display: flex;
    max-width: max-content;
    justify-content: center;
  }

  .react-datepicker__month-text {
    padding: 16px;
    font-family: "Font-500";
    font-size: 14px;
    font-weight: 500;
    color: #5e6361;
  }

  .react-datepicker__month-text:hover {
    background-color: white;
    color: #27bd63;
    font-weight: 600;
  }

  .react-datepicker__month-text--selected {
    background-color: white;
    color: #27bd63;
    font-weight: 600;
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: white;
    color: #27bd63;
    font-weight: 600;
  }
}
