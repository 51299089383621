.react-datepicker-popper {
  z-index: 100000000001 !important;
}

.react-datepicker {
  margin-top: 8px;
  z-index: 1000;
  background: #ffffff;
  border-radius: 24px;
  width: 428px;
  overflow: hidden;
  padding: 24px;
  border: none;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.2));
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__header {
  background-color: white !important;
  padding: 0px;
  border-bottom: 0px !important;
}

.react-datepicker__day-names {
  height: 46px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.react-datepicker__day-name {
  flex: 1;
}

.react-datepicker__month {
  margin: 0px;
}

.react-datepicker__week {
  height: 46px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.react-datepicker__day {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.react-datepicker__day--selected {
  background-color: white;
}

.react-datepicker__day--keyboard-selected {
  background-color: white;
}

.react-datepicker__day:has(.datepicker-calendar-disabled) {
  pointer-events: none;
}

.react-datepicker__day:hover {
  background-color: white;
}

.datepicker-calendar-day {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__day--selected .datepicker-calendar-day {
  background-color: #d8ffe5;
  color: #3eba86;
}

.react-datepicker__day--keyboard-selected .datepicker-calendar-day {
  background-color: #d8ffe5;
  color: #3eba86;
}

.react-datepicker__day:hover .datepicker-calendar-day {
  background-color: #c5c7c3;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled {
  color: #cccccc !important;
}

.react-datepicker__year-text--disabled {
  display: none !important;
}
